<template>
  <div class="content">
    <div>
      <p class="titleName">{{ $t('special.p19')}}</p>
      <pre v-text="$t('special.p84')" />
    </div>
    <div>
      <p class="titleName">{{ $t('special.p23') }}</p>
      <p>{{ $t('special.p24') }}</p>
      <p>{{ $t('special.p25') }}</p>
      <i18n path="special.p26" tag="p">
        <a
          class="text-decoration-none"
          place="here"
          :href="displayData.href"
          :download="displayData.downloadName"
        >
          {{ $t('special.p28') }}
        </a>
      </i18n>
      <p>©Formirai Co., Ltd. ©Spike Chunsoft Co., Ltd. All Rights Reserved.</p>
    </div>
    <div class="download">
      <p class="titleName">{{ $t('special.p27') }}</p>
      <div class="gif">
        <v-row>
          <v-col
            v-for="(item, i ) in displayData.gifFile"
            :key="i"
            cols="12"
            sm="4"
          >
              <a :href="item.href" :download="item.name">
              <img :src="item.href" :alt="item.name">
            </a>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ['displayData']
}
</script>
<style lang="scss" scoped>
.content{
  margin: 20px 20px 20px 20px;
  text-align: left;
  p, pre{
    color: #ffffff;
    font-weight: 500;
    white-space: pre-wrap;
    &.titleName{
      // font-family: 'Source Han Sans-bold';
      font-size: 32px;
      text-indent: 0em;
    }
    &.subtitleName{
      font-size: 26px;
      text-indent: 0em;
    }
  }
  .download{
    .gif{
      text-align:left;
    }
    img{
      // width: 100%;
      max-width: 240px;
      // height: auto;
    }
  }
}
@media only screen and (max-width: 450px) {
  .content{
    p{
      font-size: 14px;
      &.titleName{
        font-size: 20px;
      }
      &.subtitleName{
        font-size: 18px;
      }
    }
    .download{
      img{
        max-width: 300px;
        -webkit-touch-callout: default;
      }
    }
  }
}
</style>
